import * as React from "react"
import Button from "../Button/Button"
import { ContactStyles } from "./ContactStyles"

const Contact = () => {
  return (
    <ContactStyles className="section">
      <h5>Kontaktirajte nas i ostvarite odmor iz snova.</h5>
      <form 
        name="contact" 
        action="https://formspree.io/f/mqknobvj"
        method="POST"
        >
        <input placeholder="Vaše ime..." type="text" name="name" />
        <input placeholder="Vaš e-mail..." type="email" name="email" />
        <textarea
          placeholder="Vaša poruka..."
          name="message"
          rows="8"
        ></textarea>
        <Button text="Pošalji poruku" />
      </form>
    </ContactStyles>
  )
}

export default Contact
